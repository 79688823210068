// import React, { useEffect, useState } from 'react';
// import { Button, Row, Col, Card, Form, Spinner } from 'react-bootstrap';
// import HtmlHead from 'components/html-head/HtmlHead';
// import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
// import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css';
// import 'intro.js/introjs.css';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { useHistory, useLocation } from 'react-router-dom';
// import moment from 'moment';
// import uploadImageToS3, { uploadToS3 } from 'helper/AWS';
// import { Fetch } from 'helper/api';
// import { setToastData } from 'components/customs/toastSlice';
// import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';


// const Banner = () => {
//   const title = 'New Banner';
//   const description = 'Add Update Banner';
//   const [validated, setValidated] = useState(false);
//   const history = useHistory();
//   const location = useLocation();
//   const banner = location.state?.banner;
//   // console.log(moment(banner.start_date).format('l'), "banner");
//   const [initialFiles, setInitialFiles] = useState([]);



//   const [formData, setFormData] = useState({
//     mediaType: banner && banner.image_url ? 'image' : banner && banner.video_url ? 'video' : 'image',
//     isActive: banner && banner.is_active ? banner.is_active : true,
//     bannerName: banner && banner.name ? banner.name : "",
//     startDate: banner && banner.start_date ? moment(banner.start_date).toDate() : "",
//     endDate: banner && banner.end_date ? moment(banner.end_date).toDate() : "",
//     redirectionLink: banner && banner.redirection_link ? banner.redirection_link : "",
//     description: banner && banner.description ? banner.description : '',
//     files: banner && banner.files ? banner.image_url : [],
//   });

//   const [showToast, setShowToast] = useState(false);
//   const dispatch = useDispatch();

//   const breadcrumbs = [
//     { to: '', text: 'Home' },
//     { to: 'application/banners', text: 'Banners' },
//     { to: 'application/banners/add', text: 'Add' },
//   ];

//   useEffect(() => {
//     if (banner && banner.image_url) {
//       const initialFile = {
//         file: null,
//         meta: {
//           name: 'Current Banner Image',
//           previewUrl: banner.image_url,
//           status: 'done',
//           size: 0,
//         },
//       };
//       setInitialFiles([initialFile]);
//       setFormData(prev => ({
//         ...prev,
//         files: [initialFile],
//       }));
//     }
//   }, [banner]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleDateChange = (date, fieldName) => {

//     setFormData((prevData) => ({
//       ...prevData,
//       [fieldName]: date,
//     }));
//   };

//   const handleSwitchChange = (e) => {
//     const { name, checked } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: checked,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       event.preventDefault();

//       let mediaUrl = formData.mediaType === "image" ? formData.files : formData.files;

//       if (formData.files) {
//         console.log(formData.files, "formData.files")
//         mediaUrl = await uploadImageToS3(formData.files[0].file);
//         console.log(mediaUrl, "mediaUrl")
//       }

//       const formattedData = {
//         name: formData.bannerName,
//         description: formData.description,
//         redirection_link: formData.redirectionLink,
//         media: formData.mediaType,
//         is_active: formData.isActive,
//         image_url: formData.mediaType === "image" ? mediaUrl : null,
//         video_url: formData.mediaType === "video" ? mediaUrl : null,
//         start_date: moment(formData.startDate).toISOString(),
//         end_date: moment(formData.endDate).toISOString(),
//       };

//       console.log(formattedData, "------------------");

//       try {
//         const response = await Fetch('admin/banner', { method: 'POST', body: JSON.stringify(formattedData) });
//         if (response.success) {

//           alert(response.message)
//           toast.success(response.message || 'Banner created successfully!');
//           history.push('/application/banners')
//           // setShowToast(true);

//         }
//         // console.log('Form submitted successfully:', response);
//       } catch (error) {
//         console.error('Error submitting the form:', error.message);
//         toast.error('An error occurred while submitting the form.');  
//       }

//     }
//     setValidated(true);
//   };

//   const onChangeStatus = (fileWithMeta, status) => {
//     if (status === 'done') {
//       setFormData((prevData) => ({
//         ...prevData,
//         files: [...prevData.files, fileWithMeta],
//       }));
//     }
//   };


//   const dropzoneStyles = `

//     .custom-dropzone {
//       border: 3px dashed #cccccc !important;
//       min-height: 250px !important;
//       border-radius: 1rem;
//       background-color: #f8f9fa;
//       transition: all 0.3s ease;
//       min-height: 200px;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;
//       padding: 2rem;
//     }

//     .custom-dropzone:hover {
//       border-color: #5c80d1;
//       background-color: #f0f4ff;
//     }

//     .upload-icon {
//     margin:auto;
//       width: 64px;
//       height: 64px;
//       border-radius: 50%;
//       background-color: #e7eeff;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-bottom: 1rem;
//       transition: all 0.3s ease;
//     }

//     .custom-dropzone:hover .upload-icon {

//       transform: scale(1.1);
//     }

//     .custom-dropzone:hover .upload-icon svg {
//       color: white;
//     }

//     .upload-text {
//       font-size: 1.1rem;
//       font-weight: 500;
//       color: #4e4e4e;
//       margin-bottom: 0.5rem;
//       margin-top:0.5rem;
//     }

//     .upload-subtext {
//       font-size: 0.9rem;
//       color: #6c757d;
//     }

//     .file-preview-container {
//       background-color: white;
//       border-radius: 0.5rem;
//       box-shadow: 0 2px 4px rgba(0,0,0,0.05);
//       margin-top: 1rem;
//     }
//   `;

//   const DropzoneColumnPreview = ({ meta, fileWithMeta }) => {
//     const { remove } = fileWithMeta;
//     const { name, status, previewUrl = banner.image_url, size } = meta;

//     const displayUrl = status === 'done' && !size ? previewUrl : meta.previewUrl;


//     return (
//       <div className="col">
//         <div className="file-preview-container border border-1 border-separator g-0 rounded-sm position-relative z-index-1">
//           <div className="position-relative">
//             {/* {previewUrl ? (
//               <img src={previewUrl} alt="preview" className="w-100 sh-18 rounded-top" />
//             ) : (
//               <div className="w-100 sh-18 d-flex justify-content-center align-items-center bg-light rounded-top">
//                 <div className="text-center">
//                   <CsLineIcons icon={formData.mediaType === 'image' ? 'image' : 'video'} className="text-primary" size="32" />
//                 </div>
//               </div>
//             )} */}
//             {displayUrl ? (
//               <img src={displayUrl} alt="preview" className="w-100 sh-18 rounded-top" />
//             ) : (
//               <div className="w-100 sh-18 d-flex justify-content-center align-items-center bg-light rounded-top">
//                 <div className="text-center">
//                   <CsLineIcons icon={formData.mediaType === 'image' ? 'image' : 'video'} className="text-primary" size="32" />
//                 </div>
//               </div>
//             )}
//             {status === 'done' && (
//               <div className="position-absolute top-0 end-0 m-2">
//                 <div className="bg-success rounded-pill p-1">
//                   <CsLineIcons icon="check" size="12" className="text-white" />
//                 </div>
//               </div>
//             )}
//             {['error_upload_params', 'exception_upload', 'error_upload'].includes(status) && (
//               <div className="position-absolute top-0 end-0 m-2">
//                 <div className="bg-danger rounded-pill p-1">
//                   <CsLineIcons icon="close" size="12" className="text-white" />
//                 </div>
//               </div>
//             )}
//             {!['error_upload_params', 'exception_upload', 'error_upload', 'done'].includes(status) && status !== 'preparing' && (
//               <div className="position-absolute top-0 end-0 m-2">
//                 <Spinner animation="border" size="sm" variant="primary" />
//               </div>
//             )}
//           </div>
//           <div className="px-3 py-2">
//             <div className="d-flex justify-content-between align-items-center">
//               <div className="w-80">
//                 <p className="mb-1 pe-2 w-100 text-truncate font-weight-bold">{name}</p>
//                 <div className="text-small text-primary">{Math.round(size / 1000)} KB</div>
//               </div>
//               {!['preparing', 'getting_upload_params', 'uploading'].includes(status) && (
//                 <button
//                   className="btn btn-link text-muted p-0 rounded-pill hover-bg-danger"
//                   type="button"
//                   onClick={remove}
//                 >
//                   <CsLineIcons icon="bin" size="17" />
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const CustomDropzoneContent = () => (
//     <div className="text-center">
//       <div className="upload-icon">
//         <CsLineIcons icon="upload" size="24" className="text-primary" />
//       </div>
//       <p className="upload-text">Drop your {formData.mediaType} here</p>
//       <p className="upload-subtext">
//         or <span className="text-primary">browse</span> to choose a file
//       </p>
//       <p className="small text-muted">
//         {formData.mediaType === 'image' ? 'PNG, JPG or GIF' : 'MP4, WebM or OGG'} (max. 800x400px)
//       </p>
//     </div>
//   );


//   return (
//     <>
//       <style>{dropzoneStyles}</style>
//       <HtmlHead title={title} description={description} />
//       <div className="page-title-container">
//         <Row>
//           <Col md="8">
//             <h1 className="mb-0 pb-0 display-4">{title}</h1>
//             <BreadcrumbList items={breadcrumbs} />
//           </Col>
//         </Row>

//         <Card body className="mb-5 mt-5">
//           <Form noValidate validated={validated} onSubmit={handleSubmit}>
//             <Row className="mb-3 g-3">
//               <Col md="6">
//                 <div className="custom-dropzone-container">
//                   <Dropzone
//                     getUploadParams={() => ({ url: 'https://httpbin.org/post' })}
//                     PreviewComponent={DropzoneColumnPreview}
//                     onChangeStatus={onChangeStatus}
//                     multiple={false}
//                     classNames={{
//                       inputLabelWithFiles: defaultClassNames.inputLabel,
//                       dropzone: `${defaultClassNames.dropzone} custom-dropzone `,
//                     }}
//                     inputContent={CustomDropzoneContent}
//                   />


//                 </div>
//               </Col>

//               <Col md="6">
//                 {/* <Button onClick={showsToast}>Show toast</Button> */}
//                 <Form.Group className="mb-3">
//                   <Form.Label className="mb-3">Media Type</Form.Label>
//                   <div className="d-flex gap-4">
//                     <Form.Check
//                       type="radio"
//                       name="mediaType"
//                       label="Image"
//                       value="image"
//                       checked={formData.mediaType === 'image'}
//                       onChange={handleInputChange}
//                     />
//                     <Form.Check
//                       type="radio"
//                       name="mediaType"
//                       label="Video"
//                       value="video"
//                       checked={formData.mediaType === 'video'}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </Form.Group>
//                 <Form.Group className="mb-3">
//                   <Form.Label>Active</Form.Label>
//                   <Form.Check
//                     type="switch"
//                     name="isActive"
//                     checked={formData.isActive}
//                     onChange={handleSwitchChange}
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="bannerName">
//                   <Form.Label>Banner Name</Form.Label>
//                   <Form.Control
//                     required
//                     type="text"
//                     name="bannerName"
//                     value={formData.bannerName}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md="6">
//                 <Form.Group>
//                   <Form.Label>Start Date</Form.Label>
//                   <DatePicker
//                     locale="es"
//                     className="form-control"
//                     selected={formData.startDate}
//                     onChange={(date) =>
//                       setFormData((prev) => ({ ...prev, startDate: date })
//                       )}
//                     selectsStart
//                     startDate={formData.startDate}
//                     endDate={formData.endDate}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col md="6">
//                 <Form.Group>
//                   <Form.Label>End Date</Form.Label>
//                   <DatePicker
//                     locale="es"
//                     className="form-control"
//                     selected={formData.endDate}
//                     onChange={(date) => setFormData((prev) => ({ ...prev, endDate: date }))}
//                     selectsEnd
//                     startDate={formData.startDate}
//                     endDate={formData.endDate}
//                     minDate={formData.startDate}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col md="12">
//                 <Form.Group>
//                   <Form.Label>Redirection Link</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="redirectionLink"
//                     value={formData.redirectionLink}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col md="12">
//                 <Form.Group>
//                   <Form.Label>Description</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     name="description"
//                     value={formData.description}
//                     onChange={handleInputChange}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col md="12" className="mt-4">
//                 <Button type="submit" variant="primary">
//                   Save Banner
//                 </Button>
//               </Col>
//             </Row>
//           </Form>
//         </Card>
//       </div>

//     </>
//   );
// };

// export default Banner;
import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Card, Form, Spinner } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import 'intro.js/introjs.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import uploadImageToS3 from 'helper/AWS';
import { Fetch } from 'helper/api';
import { toast } from 'react-toastify';

const Banner = () => {
  const title = 'New Banner';
  const description = 'Add Update Banner';
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const banner = location.state?.banner;
  console.log(banner,"4545");
  
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    mediaType: banner?.image_url ? 'image' : banner?.video_url ? 'video' : 'image',
    isActive: banner?.is_active ?? true,
    bannerName: banner?.name ?? "",
    startDate: banner?.start_date ? moment(banner.start_date).toDate() : "",
    endDate: banner?.end_date ? moment(banner.end_date).toDate() : "",
    redirectionLink: banner?.redirection_link ?? "",
    description: banner?.description ?? '',
    file: null,
    previewUrl: banner?.image_url ?? null,
  });

  const [isUploading, setIsUploading] = useState(false);
  const isEdit = banner ? true : false

  const breadcrumbs = [
    { to: '', text: 'Home' },
    { to: 'application/banners', text: 'Banners' },
    { to: 'application/banners/add', text: 'Add' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          file: file,
          previewUrl: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setIsUploading(true);
    try {
      let mediaUrl = formData.previewUrl;

      if (formData.file) {
        mediaUrl = await uploadImageToS3(formData.file);
        console.log(mediaUrl);
      }
      
      const formattedData = {
        name: formData.bannerName,
        description: formData.description,
        redirection_link: formData.redirectionLink,
        media: formData.mediaType,
        is_active: formData.isActive,
        image_url: formData.mediaType === "image" ? mediaUrl : null,
        video_url: formData.mediaType === "video" ? mediaUrl : null,
        start_date: moment(formData.startDate).toISOString(),
        end_date: moment(formData.endDate).toISOString(),
        banner_id: banner? banner.banner_id : null
      };

      console.log(formattedData, "formattedData")
      const { banner_id, ...updatedData } = formattedData;
      const response = await Fetch('admin/banner', {
        method: isEdit ? 'PUT' : 'POST',
        body: JSON.stringify(isEdit ? updatedData : formattedData)
      });

      if (response.success) {
        toast.success(response.message || 'Banner created successfully!');
        history.push('/application/banners');
      }


    } catch (error) {
      console.error('Error submitting the form:', error.message);
      toast.error('An error occurred while submitting the form.');
    } finally {
      setIsUploading(false);
    }
    setValidated(true);
  };

  const renderUploadPlaceholder = () => (
    <div
      onClick={handleUploadClick}
      className="upload-placeholder cursor-pointer border border-1 border-separator rounded-sm"
      style={{ minHeight: '250px' }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        {formData.previewUrl ? (
          <div className="position-relative w-100 h-100">
            <img
              src={formData.previewUrl}
              alt="preview"
              className="w-100 h-100 object-fit-cover rounded-sm"
            />
            <div className="position-absolute top-0 end-0 m-2">
              <div className="bg-success rounded-pill p-1">
                <CsLineIcons icon="check" size="12" className="text-white" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="upload-icon mb-3">
              <CsLineIcons icon="upload" size="24" className="text-primary" />
            </div>
            <p className="mb-1">Drop your {formData.mediaType} here</p>
            <p className="text-muted small">
              or click to browse
            </p>
            <p className="text-muted smallest">
              {formData.mediaType === 'image' ? 'PNG, JPG or GIF' : 'MP4, WebM or OGG'} (max. 800x400px)
            </p>
          </>
        )}
      </div>
      <input
        ref={fileInputRef}
        type="file"
        className="d-none"
        accept={formData.mediaType === 'image' ? 'image/*' : 'video/*'}
        onChange={handleFileSelect}
      />
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row>
          <Col md="8">
            <h1 className="mb-0 pb-0 display-4">{title}</h1>
            <BreadcrumbList items={breadcrumbs} />
          </Col>
        </Row>

        <Card body className="mb-5 mt-5">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Col md="6">
                {renderUploadPlaceholder()}
              </Col>

              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-3">Media Type</Form.Label>
                  <div className="d-flex gap-4">
                    <Form.Check
                      type="radio"
                      name="mediaType"
                      label="Image"
                      value="image"
                      checked={formData.mediaType === 'image'}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      type="radio"
                      name="mediaType"
                      label="Video"
                      value="video"
                      checked={formData.mediaType === 'video'}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Active</Form.Label>
                  <Form.Check
                    type="switch"
                    name="isActive"
                    checked={formData.isActive}
                    onChange={(e) => setFormData(prev => ({ ...prev, isActive: e.target.checked }))}
                  />
                </Form.Group>
                <Form.Group controlId="bannerName">
                  <Form.Label>Banner Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="bannerName"
                    value={formData.bannerName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={formData.startDate}
                    onChange={(date) => setFormData(prev => ({ ...prev, startDate: date }))}
                    selectsStart
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={formData.endDate}
                    onChange={(date) => setFormData(prev => ({ ...prev, endDate: date }))}
                    selectsEnd
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    minDate={formData.startDate}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <Form.Label>Redirection Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="redirectionLink"
                    value={formData.redirectionLink}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md="12">
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md="12" className="mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isUploading}
                >
                  {isUploading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Saving...
                    </>
                  ) : (
                    'Save Banner'
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Banner;