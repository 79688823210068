
import AWS from 'aws-sdk';

const uploadImageToS3 = async (file) => {
    // Configure AWS
    AWS.config.update({
        region: "ap-south-1",
        accessKeyId: "AKIA4MTWHUBWFKKXY7HO",
        secretAccessKey: "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"
    });

    // Create S3 instance
    const s3 = new AWS.S3();

    // Generate unique filename
    const fileName = `banners/${Date.now()}-${file.name}`;

    // Prepare upload parameters
    const uploadParams = {
        Bucket: "ottenn-media", // Replace with your actual bucket name
        Key: fileName,
        Body: file,
        ContentType: file.type
    };

    try {
        // Upload to S3
        const result = await s3.upload(uploadParams).promise();

        console.log(result, "result")        // Return the S3 URL
        return result.Location;
    } catch (error) {
        console.error("S3 Upload Error:", error);
        throw error;
    }
};

export default uploadImageToS3;